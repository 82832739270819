@import 'common/styling/_variables.scss';

.spain-otp-container {
	width: 300px;
	text-align: center;

	.login-description {
		margin-bottom: 10px;
		p {
			font-size: 1.6rem;
		}
	}

	.description {
		font-size: 0.8rem;
	}

	#main-form {
		input.btn {
			&.btn-default {
				font-size: 1.7rem;
				border-radius: 0.8rem;
				padding: 1rem 3.2rem;
			}
			&.btn-default:hover {
				background: #eea87e
					radial-gradient(circle, transparent 0.5%, #eea87e 1%)
					center/20000%;
				border-color: #eea87e;
			}
		}
	}
}
