@import 'common/styling/_variables.scss';

#footer {
	justify-content: flex-end;
	width: 100%;
	background-color: #585652;
	color: $white;
	padding: 30px;
	margin-top: auto;
	width: 100%;
	max-width: 1440px;
	display: flex;
	flex-direction: column;

	div,
	span {
		color: $white;
	}

	.footer-top-information {
		display: flex;
		flex-direction: column;

		.footer-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@include col-12;
			margin-top: 3rem;

			span {
				margin-top: 0.5rem;
			}

			svg {
				margin-right: 1rem;
			}

			.footer-contact-container,
			.footer-customer-support,
			.footer-about-us {
				margin-top: 3rem;

				display: flex;
				flex-direction: column;
			}
			.footer-address-information {
				display: flex;
				flex-direction: column;

				.companyName {
					margin-top: 0;
				}
			}

			.footer-links {
				margin-left: 0;
				margin-top: 0;
				width: 100%;
			}
		}

		.logo-container {
			display: flex;
			justify-content: center;

			img.logo-footer {
				height: 4.5rem;
				margin: 0;
			}
		}
	}

	.footer-copy-right {
		display: flex;
		flex-direction: row-reverse;
		margin-top: 3rem;
		padding-top: 1rem;
		border-top: 1px solid #857f7b;
	}

	@media screen and (min-width: $screen-md) {
		padding: 6rem;

		.footer-top-information {
			.logo-container {
				justify-content: left;
				img.logo-footer {
					margin-bottom: 2.5rem;
				}
			}

			.footer-content {
				flex-direction: row;
				margin-top: 0;

				.footer-contact-container,
				.footer-customer-support,
				.footer-about-us {
					margin-top: 0;
					@include col-2;
				}

				.footer-customer-support {
					text-align: right;					
				}

				.footer-address-information {
					@include col-8;
				}
			}
		}
	}
}
