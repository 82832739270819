@import 'common/styling/_variables.scss';

.success-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	background-color: $cream-dark;
	min-height: 5.8rem;
	padding: 3.7rem 3rem 2rem 8rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
	width: 100%;
	border-radius: $border-radius-base;

	.success-img-box {
		margin: 30px 0;
		border: 3px solid #1f9245;
		border-left-color: #1f9245;
		position: relative;
		display: inline-block;
		vertical-align: top;
		border-radius: 50%;
		width: 120px;
		height: 120px;
	}

	.checkmark {
		&.draw:after {
			animation-duration: 1.3s;
			animation-timing-function: ease;
			animation-name: success-checkmark;
			transform: scaleX(-1) rotate(135deg);
		}
		&:after {
			opacity: 1;
			height: 60px;
			width: 30px;
			transform-origin: left top;
			border-right: 5px solid #1f9245;
			border-top: 5px solid #1f9245;
			content: '';
			left: 26px;
			top: 60px;
			position: absolute;
		}
	}
}

// Checkmark success animation
@keyframes success-checkmark {
	0% {
		height: 0;
		width: 0;
		opacity: 1;
	}
	20% {
		height: 0;
		width: 30px;
		opacity: 1;
	}
	40% {
		height: 60px;
		width: 30px;
		opacity: 1;
	}
	100% {
		height: 60px;
		width: 30px;
		opacity: 1;
	}
}
