@import 'common/styling/_variables.scss';

#logout-page {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	overflow: auto;
	min-width: 100%;
	min-height: 100%;
	background-image: url('/img/background-images/woman_hanging_outside_car_window.webp');
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;

	.logout-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto auto;

		.spinner-container {
			box-shadow: 0px 0px 20px 10px rgba(97, 97, 97, 0.2);
			text-align: center;
			width: 25rem;
			height: 10rem;
			border-radius: 5px;
			background-color: #fff;
			padding: 2rem;
		}

		.error-container {
			max-width: 500px;
		}
	}

	img {
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 20rem;
		margin-top: 1.5rem;
		filter: unset;
		@media (min-width: $screen-md) {
			max-width: 195px;
		}
	}
}
