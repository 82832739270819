@import 'common/styling/_variables.scss';

.error-box {
	background-color: #ece9e1;
	position: relative;
	min-height: 58px;
	padding: 37px 30px 37px 80px;
	margin: 1rem auto 2.4rem auto;
	width: 100%;
	color: $danger;
	border-radius: 5px;

	&::before {
		content: '';
		background: url('/img/error-icons/info-icon-error-darkred.svg');
		display: inline-block;
		background-size: cover;
		width: 30px;
		height: 30px;
		margin: 0;
		position: absolute;
		top: 50%;
		left: 30px;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.error-box-content-container {
		display: flex;
		flex-direction: column;

		.button-container {
			margin-top: 1.5rem;
			margin-left: auto;
			margin-right: auto;
		}

		h3 {
			text-align: center;
		}
	}
}
