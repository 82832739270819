@import 'common/styling/_variables.scss';

.loan-offer-header.top-up {
	.loan-offer-box {
		display: flex;
		flex-direction: column;

		.loan-offer-box-calculation,
		.loan-offer-box-installment {
			display: flex;
			flex-direction: column;
		}

		.loan-offer-box-calculation {
			margin-top: 1rem;
			border-radius: $border-radius-base;
			border: 1px solid $primary-warmgray-5;

			span:first-child {
				color: $primary-warmgray-11;
				font-size: 1.5rem;
			}
			span:nth-child(3),
			span:nth-child(2) {
				color: #de996f;
				font-size: 2rem;
			}

			.box-addition {
				text-align: center;
				display: grid;
				grid-template-columns: repeat(7, 1fr);
				align-items: center;
				width: 100%;

				.current-balance,
				.plus-sign,
				.approved-amount {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
				.current-balance {
					grid-column: 1/4;
					padding: 15px 10px;
				}
				.approved-amount {
					grid-column: 5/8;
					padding: 15px 10px;
				}

				.plus-sign {
					grid-column: 4/5;

					.plus {
						width: 25px;
						height: 25px;

						&::before {
							content: url('/img/header-icons/plus_tf21.svg');
							position: relative;
							z-index: 1;
						}
					}
				}
			}
			.box-equal {
				display: flex;
				flex-direction: column;
				align-items: center;

				width: 100%;

				.new-balance {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 5px 10px 15px 10px;
					margin-top: 0;
				}
				.equal-sign {
					width: 25px;
					height: 25px;

					.equal {
						&::before {
							content: url('/img/header-icons/equal_tf21.svg');
							position: absolute;
							width: 25px;
							height: 25px;
							z-index: 1;
						}
					}
				}
			}
		}
		//Desktop styling
		@media screen and (min-width: $screen-md) {
			flex-direction: row;

			.loan-offer-box-calculation {
				width: 70%;
				flex-direction: row;

				.box-equal {
					width: 40%;
					flex-direction: row;
					justify-content: space-evenly;

					.equal-sign {
						height: 15px;
					}

					.new-balance {
						padding: 15px 10px;
					}
				}
				.box-addition {
					width: 60%;
				}
			}

			.loan-offer-box-installment {
				width: 30%;
				justify-content: center;
			}
		}
	}

	.loan-offer-table {
		.loan-offer-item.existing-loan {
			div:last-child {
				font-weight: 600;
			}
		}
	}
}
