@import 'common/styling/_variables.scss';

.header-tooltip {
	position: relative;
	padding: 2px 6px;
	width: 15px;
	height: 15px;
	&::before {
		content: url('/img/tooltip-icons/tooltip-bronze.svg');
	}
	.tooltip-text {
		width: 190px;
		color: $primary-warmgray-2;
		background-color: $primary-bronze-1;
		border: 1px solid $primary-bronze-1;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 400;
		padding: 15px;
		text-align: left;
		margin-left: -95px;
		position: absolute;
		left: 350%;
		top: -830%;

		.arrow-down {
			width: 0;
			height: 0;
			border-top: 10px solid $primary-bronze-1;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			margin: 0;
			position: absolute;
			right: 60%;
			bottom: -9%;
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
		}
	}
}
