.thank-you {
	.success-msg-checkmark-animation {
		padding: 0 24px 26px 30px;
		text-align: center;
	}

	.success-message {
		padding: 0 15px;
	}

	&.norway {
		padding-bottom: 2rem;

		.box-info {
			margin-top: 1rem;
		}
	}

	.thank-you-back-button-container {
		display: flex;
		justify-content: center;
		padding-bottom: 2rem;
	}

	.thank-you-printpn-button-container {
		margin-top: 15px;

		.download-pn-button {
			display: flex;
			justify-content: space-between;
			align-items: center;
			max-width: 250px;
			min-width: 250px;
			min-height: 46px;
			text-decoration: none;

			img {
				height: 20px;
			}
		}

		.download-pn-spinner {
			#loading-spinner {
				transform: translate(90px, -10px);
			}
		}
	}
}