#circle-spinner {
	border: 2px rgba(251, 251, 251, 0.603) solid;
	border-top: 2px #de996f solid;
	border-left: 3px #de996f solid;
	border-bottom: 3px #de996f solid;
	height: 6rem;
	width: 6rem;
	border-radius: 50%;
	-webkit-animation: circle-spinner-animation 1s infinite linear;
	animation: circle-spinner-animation 1s infinite linear;
	margin-left: auto;
	margin-right: auto;
}

@keyframes circle-spinner-animation {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
