@import 'common/styling/_variables.scss';
@import 'common/styling/phone-flags.scss';

#main-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;

	/* General styling form builder */
	.fieldset-description {
		text-align: left;
		padding-left: 1.7rem;
		padding-right: 1.7rem;
		margin: 2rem 0 1rem 0;
	}

	.description {
		font-size: 1.5rem;
	}

	input[type='submit'] {
		max-width: 335px;
		min-width: 300px;
	}

	input.btn {
		&.btn-default {
			font-size: 1.7rem;
			border-radius: 0.8rem;
			padding: 1rem 3.2rem;
		}
		&.btn-default:hover {
			background: #eea87e
				radial-gradient(circle, transparent 0.5%, #eea87e 1%)
				center/20000%;
			border-color: #eea87e;
		}
	}

	@media screen and (min-width: $screen-md) {
		max-width: 670px;
	}

	.formTooltip {
		cursor: pointer;
		display: inline-block !important;

		.icon {
			content: url('/img/information-icons/info_bronze.svg');
			height: 22px;
			width: 22px;
			padding-top: 4px;
		}
	}

	/* BUTTONS */
	.btn-primary:not(:disabled):not(.disabled).active:focus,
	.btn-primary:not(:disabled):not(.disabled):active:focus,
	.show > .btn-primary.dropdown-toggle:focus {
		box-shadow: none;
	}

	.btn-nostyle {
		border: 0;
		padding: 0;
		width: inherit;
		text-align: inherit;
		background: inherit;
		color: inherit;
		font-family: inherit;
	}
	.btn-nostyle.active.focus,
	.btn-nostyle.active:focus,
	.btn-nostyle.focus,
	.btn-nostyle:active.focus,
	.btn-nostyle:active:focus,
	.btn-nostyle:focus {
		outline: none;
		box-shadow: none;
	}

	.btn:not(.dropdown-toggle):not(.authButton).btn-default:not(:disabled):not(
			.disabled
		),
	.btn:not(.dropdown-toggle):not(.authButton).btn-primary:not(:disabled):not(
			.disabled
		) {
		background-position: center !important;
		transition: background 399ms !important;
	}
	.btn-info:not(:disabled):not(.disabled).active:focus,
	.btn-info:not(:disabled):not(.disabled):active:focus,
	.show > .btn-info.dropdown-toggle:focus {
		box-shadow: none;
	}

	.btn-info:not(:disabled):not(.disabled).active:focus,
	.btn-info:not(:disabled):not(.disabled):active:focus,
	.show > .btn-info.dropdown-toggle:focus {
		background-color: transparent;
		border-color: #f6f5ee;
	}
	.btn {
		width: unset;
		&:focus,
		&:active {
			box-shadow: none;
		}
		&.btn-default,
		&.btn-primary {
			background-color: #de996f;
			border-color: #de996f;
			color: #fff;
			border-radius: 0.35rem;
			padding: 0.6rem 3.6rem 0.6rem;
			letter-spacing: 0.7px;
			font-size: 1.1rem;
			display: block;
		}
		&.btn-primary.btn-dark,
		&.btn-secondary {
			background-color: $primary-warmgray-11;
			border-color: $primary-warmgray-11;
			color: #fff;
			border-radius: 0.35rem;
			font-size: 1.1rem;
			padding: 0.6rem 3.6rem 0.6rem;
			letter-spacing: 0.7px;
		}
		&.btn-secondary.btn-light,
		&.btn-info,
		&.btn-outline {
			background-color: transparent;
			border-color: #f6f5ee;
			color: #f6f5ee;
			font-size: 1rem;
			font-weight: 700;
			letter-spacing: 0.6px;
			padding: 0.4rem 1rem;
			border-radius: 0.35rem;
		}
		&.btn-primary:not(:disabled):not(.disabled):active,
		&:not(.dropdown-toggle):not(.authButton).btn-primary:not(:disabled):not(
				.disabled
			):hover {
			background: #eea87e
				radial-gradient(circle, transparent 0.5%, #eea87e 1%)
				center/20000%;
			border-color: #eea87e;
		}
		&:not(.dropdown-toggle):not(.authButton).btn-primary.btn-dark:not(
				:disabled
			):not(.disabled):hover {
			background: #6b6a64
				radial-gradient(circle, transparent 0.5%, #6b6a64 1%)
				center/20000%;
			border-color: #6b6a64;
		}
		&.btn:not(.dropdown-toggle):not(.authButton).btn-secondary:not(
				:disabled
			):not(.disabled):hover {
			background: #6b6a64
				radial-gradient(circle, transparent 0.5%, #6b6a64 1%)
				center/20000%;
		}
		&:not(.dropdown-toggle):not(.authButton).btn-secondary:not(
				:disabled
			):not(.disabled):hover {
			background-color: rgba(182, 179, 179, 0.63);
		}
		&:not(.dropdown-toggle):not(.authButton).btn-secondary.btn-light:not(
				:disabled
			):not(.disabled):hover,
		&:not(.dropdown-toggle):not(.authButton).btn-info:not(:disabled):not(
				.disabled
			):hover,
		&:not(.dropdown-toggle):not(.authButton).btn-outline:not(:disabled):not(
				.disabled
			):hover {
			background-color: rgba(246, 245, 238, 0.2);
			border-color: #f6f5ee;
		}
	}

	/* LABELS & GENERAL FORM STYLING */
	.form-group {
		.control-label {
			background-color: transparent !important;
			position: relative;
			margin-bottom: 0;

			span {
				line-height: 0.6;
				position: relative;
			}

			.required {
				top: 0.4rem;
				color: $danger;
				overflow: visible;
				max-width: 0;
				max-height: 0;
			}
		}

		&.label-floating.disabled .control-label {
			background-color: transparent !important;
		}

		&.label-floating:not(.control-slider) label.control-label,
		&.control-tel label.control-label,
		&.control-phoneNumber label.control-label,
		&.control-dropdown label.control-label {
			top: 13px;
			left: 30px;
			z-index: 1;
			will-change: left, top, contents;
			position: absolute;
			pointer-events: none;
			-webkit-transition: 0.3s ease all;
			-o-transition: 0.3s ease all;
			transition: 0.3s ease all;
		}
		&.label-floating.is-focused:not(.control-slider) label.control-label,
		&.label-floating:not(.is-empty):not(.control-slider)
			label.control-label,
		&.control-dropdown:not(.is-empty) label.control-label {
			top: 3px;
			left: 30px;
			font-size: 1.5rem;
			line-height: 1.07142857;
			background-color: white;
			padding: 0 4px;
		}
		&.label-floating.is-focused :-moz-placeholder {
			opacity: 1;
		}
		&.label-floating.is-focused ::-moz-placeholder {
			opacity: 1;
		}
		&.label-floating .control-label .required,
		&.control-dropdown .control-label .required {
			font-size: 1.5rem;
		}

		&.disabled label {
			color: #979797;
		}

		.form-control:not(.slider-input),
		textarea {
			height: 49px;
			font-size: inherit;
			padding: 1.7rem 1rem 1.1rem 1.7rem;
			border: 1px solid $primary-warmgray-11;
			border-radius: 0.4rem;
			&:focus {
				color: $primary-warmgray-11;
				border: 1px solid #de996f !important;
				box-shadow: 0 0 0 0.08rem #de996f;
				outline: 0;
			}
		}
		textarea {
			min-height: 150px;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: rgba(238, 238, 238, 0.452);
			color: #979797;
		}
	}

	/* INPUT FIELDS */

	//check so that this one does not break anything in no..... <=====
	form input[type='submit'] {
		@include btnPrimaryBronze;
	}

	// Firefox
	input[type='number'] {
		-moz-appearance: textfield;
	}
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	form .field .inputbox::before {
		margin-right: 0.5rem;
		border: none;
		position: absolute;
		top: -0.7rem;
		right: 0.5rem;
		margin: 0;
		width: 25px;
		height: 25px;
		z-index: 1;
	}

	/* ERROR */

	span.error {
		position: relative;
		font-size: 1.5rem;
		color: #8d191d;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 0 15px;
		-webkit-animation-duration: 299ms;
		animation-duration: 299ms;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		max-width: 570px;
	}

	form .field.has-error {
		.form-control {
			border: 1px solid #8d191d;
			color: #8d191d;
		}
		.control-label,
		.special-label {
			color: #8d191d;
		}

		.control-label p {
			color: $danger;
		}

		.inputbox::before {
			content: url('/img/error-icons/error_warning_darkred.svg');
		}

		&.control-checkbox {
			label {
				> span {
					border-color: $danger;
				}
			}
		}
	}

	/* DROPDOWNS */

	/* Dropdown form-builder component styling */
	.form-box.active .dropdown-extension .btn {
		z-index: 999998;
		position: relative;
	}

	/* General dropdown styling */
	.form-group.control-dropdown {
		* {
			cursor: pointer;
		}

		&.field.is-empty label.control-label {
			top: 1rem;
		}
		&.field.has-value label.control-label {
			top: 3px;
			left: 30px;
			font-size: 1.5rem;
			line-height: 1.07142857;
			background-color: white;
			padding: 0 4px;
		}

		.dropdown-toggle {
			&::after {
				content: none;
			}

			&::before {
				@include dropdownArrow;
			}

			&.btn.btn-default {
				@include fontBase;
				border-color: $primary-warmgray-11;
				width: 100%;
				min-height: 45px;
				height: auto;
				text-align: left;
				white-space: normal;
				outline: none;
				box-shadow: none;
				background-color: $white;
				padding-left: 15px;
				padding-top: 15px;
			}
		}

		ul.dropdown-menu {
			width: 99.3%;
			padding: 0rem 0;
			padding-top: 0;
			margin-top: -20px !important;
			transform: translate3d(0px, 74px, 0px) !important;
			top: 0px !important;
			bottom: auto !important;
			margin-top: 0px;
			overflow-y: scroll;
			overflow-x: hidden;
			max-height: 175px;
			z-index: 2050;
			box-shadow: 2px 0 0 2px $white, 2px -52px 0 2px $white,
				2px 0 1px 5px $primary-bronze-1,
				2px -52px 1px 5px $primary-bronze-1;
			border: none;
			border-radius: 1px;

			&:not(.show) {
				li {
					display: none;
				}

				a.dropdown-item {
					width: 0;
					height: 0;
				}
			}
			&.show {
				@include transitionBase;

				li {
					display: block;

					a.dropdown-item {
						position: relative;
						color: inherit;
						width: auto;
						margin: 0 10px;
						padding: 0.5rem 3.5rem 0.5rem 0.5rem;
						border-radius: $border-radius-base;
						white-space: normal;
					}
				}

				li {
					a:hover,
					a:focus {
						background: $primary-warmgray-2;
					}

					a.ticked:before {
						display: block;
						content: url('/img/form-field-icons/tick/tick_tf21.svg');
						position: absolute;
						right: 0.5rem;
						line-height: 0;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}

			&::-webkit-scrollbar {
				width: 6px;
				background-color: $primary-warmgray-5;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $primary-bronze-1;
				border-radius: 0.5rem;
			}
		}

		.dropdown.show {
			.dropdown-toggle.btn {
				border: none;
				border-bottom: 1px solid rgba(0, 0, 0, 0.15);
				padding-top: 10px;

				&::before {
					transform: rotate(180deg);
					@include transitionBase;
				}

				&:focus {
					border: 0 !important;
					border-radius: 0;
					border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
				}
			}

			a:hover {
				text-decoration: none;
			}
		}
	}

	/* PHONENUMBER & TEL INPUT STYLING */
	.form-group.control-tel,
	.form-group.control-phoneNumber {
		label.control-label {
			padding-left: 7rem;

			.required {
				font-size: 1.5rem;
			}
		}

		&.field.has-value,
		&.field.is-focused {
			.control-label {
				top: 2px;
				left: 35px;
				font-size: 1.5rem;
				line-height: 1.07142857;
				background-color: white;
				padding: 0 4px;
				padding-left: 35px !important;
			}
		}

		/* PHONE INPUT */
		.react-tel-input {
			width: 100% !important;

			input.form-control {
				width: 100% !important;
				height: 49px;
				padding: 2.2rem 1rem 1.4rem 5rem;
				color: $primary-warmgray-11;
				border: 1px solid $primary-warmgray-11;
				border-radius: 0.4rem;
			}

			.flag-dropdown {
				width: 4.5rem;
				border: 0 !important;
				background-color: transparent !important;

				.selected-flag {
					background-color: transparent;
					width: 4.5rem;
					border-right: 1px solid $primary-warmgray-11;
					margin-right: 1.6rem;
				}
				&.open {
					z-index: 2080;
					.selected-flag {
						background-color: transparent;
					}
				}
			}
			.flag {
				background-color: transparent;

				.arrow {
					border: 0 !important;
					top: 0;

					&::before {
						content: ' ';
						background: url('/img/form-field-icons/arrows/dropdown_arrow_tf21.svg');
						background-size: cover;
						display: block;
						width: 15px;
						height: 15px;
					}
					&.up {
						&::before {
							transform: rotate(-180deg);
							@include transitionBase;
						}
					}
				}
			}
			.special-label {
				display: none;
			}
		}
	}

	/* TEL INPUT */
	.tel-container-wrapper {
		.field.has-value,
		.field.is-focused {
			&.form-group.control-tel {
				.control-label {
					left: 40px;
				}

				input[type='tel'].form-control {
					padding-left: 70px;
				}
			}
		}

		.field.is-empty {
			&.form-group.control-tel {
				.control-label {
					padding-left: 45px;
				}
			}
		}

		.inputbox {
			&::before {
				display: block;
				position: absolute;
				content: ' ';
				border-right: 1px solid $primary-warmgray-11;
				left: 60px;
				height: 49px;
			}

			&::after {
				display: block;
				position: absolute;
				content: ' ';
				background-image: url('/img/form-field-icons/flags/tel-input-material.png');
				background-repeat: no-repeat;
				height: 20px;
				width: 25px;
				top: 12px;
				left: 26px;
			}
		}

		&.de {
			.inputbox::after {
				background-position: -144px -96px;
			}
		}
		&.at {
			.inputbox::after {
				background-position: -336px -24px;
			}
		}
		&.no {
			.inputbox::after {
				background-position: -288px -240px;
			}
		}
		&.es {
			.inputbox::after {
				background-position: 0 -120px;
			}
		}
	}

	/* RADIO BUTTONS  */
	.form-group.control-radio {
		.control-label {
			position: static;
		}

		.inputbox {
			order: 2;
			display: flex;
			flex-direction: row;
			padding: 1rem 2rem;

			.control-label {
				display: flex;
				flex-direction: row;
				margin-right: 3rem;

				* {
					cursor: pointer;
				}

				.icon {
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					margin: 0 1rem 0 0.75rem;
					width: 2.5rem;
					height: 2.5rem;
					min-width: 2.5rem;
					max-width: 2.5rem;
					background-color: $white;
					color: $primary-bronze-1;
					border: 1px solid $primary-warmgray-11;
					border-radius: 50%;
					display: grid;
					place-content: center;
				}
			}
		}

		input {
			+ label {
				> .icon {
					&::before {
						display: block;
						content: ' ';
						position: relative;
						z-index: 1;
						width: 0;
						height: 0;
						top: 0;
						left: 0.01rem;
						@include transitionBase;
					}
				}
			}

			&:checked + label {
				.icon {
					&::before {
						background-color: $primary-bronze-1;
						border-radius: $border-radius-large;
						width: 1.5rem;
						height: 1.5rem;
						top: 0;
						left: 0.01rem;
						@include transitionBase;

						@media screen and (min-width: $screen-xl) {
							top: 0.05rem;
						}
						@-moz-document url-prefix() {
							top: 0.05rem;
							left: 0.01rem;
						}
					}
				}
			}
		}

		.description {
			max-height: 1000px;
			padding: 0 1.5rem 0.5rem;
		}

		&.required {
			label {
				span {
					&.required {
						display: none;
					}
				}
			}
		}

		// Another required "star" because radio buttons uses description as label
		&.required {
			.description {
				div:not(.formTooltip) {
					&::after {
						content: '*';
						font-size: 3rem;
						color: $primary-warmgray-9;
						position: absolute;
						margin-top: -10px;
					}
				}

				.formTooltip {
					margin-left: 5px;
				}
			}
		}
	}

	/* CHECKBOXES */

	.form-group.control-checkbox {
		label {
			cursor: pointer;
			display: flex;
			flex-direction: row;
			margin-right: 3rem;

			> span {
				display: block;
				position: relative;
				width: 32px;
				min-width: 32px;
				height: 32px;
				border-radius: $border-radius-base;
				background-color: $white;
				margin-right: 2rem;
				border: 0.15rem solid $primary-warmgray-11;
				transition: border 499ms ease-in-out;

				&::before {
					display: block;
					content: url('/img/form-field-icons/tick/tick_tf21.svg');
					position: relative;
					top: 0.5rem;
					left: 0.6rem;
					transform: scale(0);
					@include transitionBase;
				}
			}

			.required {
				display: none;
			}
		}

		.formTooltip,
		.description {
			display: none !important;
		}

		&.is-focused {
			label span:first-child {
				@include fieldFocus;
			}
		}

		&.has-value {
			label {
				> span {
					&::before {
						transform: scale(1.2);
					}
				}
			}
		}
	}

	/* SLIDER */

	.form-group.control-slider {
		background-color: $white;
		margin-bottom: 0;
		padding: 0 30px 20px 30px;

		.form-control:focus {
			// all: unset;
			box-shadow: unset;
		}

		label.control-label {
			top: 30px;
		}

		.slider-input-parent {
			.btn-decrease,
			.btn-increase {
				display: none;
			}

			input.slider-input {
				font-size: inherit;
				border: none;
				background-color: transparent;
				width: 100%;
				text-align: right;
				padding: 0;
			}
		}

		.range-parent {
			background-image: none;

			input[type='range'] {
				width: 100%;
				outline: 0;
				background-color: rgb(248, 199, 169);
				background-image: linear-gradient(
					rgb(222, 153, 111),
					rgb(222, 153, 111)
				) !important;
				border-radius: 30px;
				position: relative;
				background-repeat: no-repeat;
				display: block;
				color: $primary-warmgray-2;
				height: 5px;
				cursor: pointer;
				z-index: 101;
				-webkit-appearance: none;
			}

			input[type='range'] {
				&::-webkit-slider-runnable-track,
				&::-moz-range-track,
				&::-ms-track {
					@include track;
				}
				&::-webkit-slider-thumb {
					@include shadow($shadow-1);
					background-color: $primary-warmgray-11;
					border: 2px solid $primary-warmgray-5;
					border-radius: 50%;
					-webkit-appearance: none;
					width: 1.8rem;
					height: 1.8rem;
					cursor: pointer;
				}
			}
		}
		.values {
			.min {
				text-align: left;
				color: $primary-warmgray-5;
				padding: 0;
			}
			.max {
				text-align: right;
				color: $primary-warmgray-5;
				padding: 0;
			}
		}
	}
}

.de {
	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.norway .inputbox .react-tel-input .form-control {
		padding-left: 50px !important;
		font-size: 1rem;
		font-family: 'Raleway', Arial;
	}
	.austria .inputbox .react-tel-input .form-control {
		padding-left: 50px !important;
		font-size: 1rem;
		font-family: 'Raleway', Arial;
	}
	span.error {
		position: relative;
		font-size: 1rem !important;
		color: #8d191d;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 0 15px;
		-webkit-animation-duration: 299ms;
		animation-duration: 299ms;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		max-width: 570px;
	}

	.form-control,
	textarea {
		height: 49px;
		padding: 1.7rem 1rem 1.1rem;
		color: #575651;
		border: 1px solid #575651;
		border-radius: 0.4rem;
		&:focus {
			color: #575651;
			border: 1px solid #de996f !important;
			box-shadow: 0 0 0 0.08rem #de996f;
			outline: 0;
		}
	}
	textarea {
		min-height: 150px;
	}
	.control-label {
		background-color: transparent !important;
	}
	.form-control:disabled,
	.form-control[readonly] {
		background-color: rgba(238, 238, 238, 0.452);
		color: #979797;
	}
	.disabled label {
		color: #979797;
	}
}