@import 'common/styling/_variables.scss';

.sign-pn-view-contract-section {
	margin-top: 2rem;

	.col-spinner {
		margin-top: 3rem;
	}

	.sign-pn-approve-checkbox-section {
		display: flex;
		margin-top: 15px;

		.checkbox-container {
			@include checkboxLarge;
		}
	}

	.sign-pn-description {
		display: flex;
		flex-direction: column;

		div {
			margin-bottom: 1rem;
		}
	}

	#view-pn-container {
		width: 100%;
		position: relative;
		margin-top: 1rem;

		.view-pn-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;

			.react-pdf__Document {
				overflow-y: scroll;
				overflow-x: scroll;
				max-height: 500px;
				max-width: 100%;
				border: 1px solid $white;
				border-radius: 1rem;
			}
		}

		.zoom-container {
			cursor: pointer;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 2.5rem;
			bottom: 2.5rem;
			border-radius: 1rem;
			background-color: transparent;

			.arrow-down {
				display: block;
				opacity: 0;
				visibility: hidden;
				width: 3rem;
				height: auto;
				filter: invert(5%) sepia(59%) saturate(180%) hue-rotate(32deg)
					brightness(111%) contrast(87%);
				transition: opacity 0.3s 0.1s ease-in-out,
					visibility 0.3s ease-in-out;
			}

			.zoom-buttons {
				display: flex;
				visibility: hidden;
				border-radius: 1rem;
				width: 6.5rem;
				background-color: $cream-rich;
				transition: opacity 0.3s 0.1s ease-in-out,
					visibility 0.3s ease-in-out;

				.zoom-btn {
					text-align: center;
					margin: unset;
					padding: 1rem;

					&:hover:not(:disabled) {
						img {
							filter: invert(48%) sepia(70%) saturate(373%)
								hue-rotate(334deg) brightness(101%)
								contrast(79%);
						}
					}
				}
			}

			.magnifying-glass {
				border-radius: 5rem;
				height: 6rem;
				width: 6rem;
				background-color: $cream-rich;
				transition: height 0.3s ease-in-out, width 0.3s ease-in-out;

				&:hover {
					img.zoom-icon-magnifying-glass {
						filter: invert(48%) sepia(70%) saturate(373%)
							hue-rotate(334deg) brightness(101%) contrast(79%);
					}
				}

				.zoom-icon-magnifying-glass {
					padding: 1rem;
					margin: 0 auto;
					height: 4.8rem;
					transition: color 0.3s ease-in-out, height 0.3s ease-in-out;
				}
			}

			.zoom-content-container {
				display: flex;
				flex-direction: column-reverse;
				align-items: center;
				justify-content: center;
			}

			&.inactive {
				.arrow-down,
				.zoom-buttons {
					visibility: hidden;
					opacity: 0;
				}
			}

			&.active {
				right: 2.5rem;

				.zoom-buttons,
				.arrow-down {
					visibility: visible;
					opacity: 1;
				}

				.magnifying-glass {
					height: 5rem;
					width: 5rem;

					.zoom-icon-magnifying-glass {
						height: 4rem;
					}
				}
			}
		}
	}

	.sign-pn-download-pn-section {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 15px;
		margin-bottom: 15px;

		.download-pn-button {
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-width: 250px;
			min-height: 46px;
			text-decoration: none;
			margin: unset;

			img {
				height: 20px;
			}
		}

		@media screen and (min-width: $screen-md) {
			justify-content: flex-end;
		}
	}

	::-webkit-scrollbar {
		width: 15px;
	}

	::-webkit-scrollbar-track {
		border-radius: 5px;
		background: $cream-rich;
	}

	::-webkit-scrollbar-thumb {
		background: $primary-warmgray-9;
		border-radius: 10px;
	}
}
