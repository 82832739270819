@import 'common/styling/_variables.scss';

#menu {
	display: flex;
	justify-content: center;
	height: 8rem;

	header {
		width: 100%;
		transition: height 0.5s;

		.menu-container {
			background-color: #de996e;
			max-width: 1440px;
			position: relative;
			height: 100%;
			margin: auto;

			.header-container {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 100%;
				margin: auto 3rem;

				img {
					height: 4.5rem;
					margin: 0;
				}

				.sign-out {
					display: flex;
					align-items: center;

					a {
						text-decoration: none;
					}
				}
			}
		}

		@media screen and (min-width: $screen-md) {
			height: 80px;

			.menu-container {
				.header-container {
					margin: auto 6rem;
				}
			}
		}
	}
}

#login-page {
	#menu {
		.sign-out {
			display: none;
		}
	}
}
