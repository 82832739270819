@import 'common/styling/_variables.scss';

.read-more-expander {
	display: flex;
	flex-direction: column;

	&.content-top {
		flex-direction: column-reverse;
	}

	.expander-item {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		cursor: pointer;

		.fa-chevron-down,
		.fa-chevron-up {
			font-size: 20px;
		}

		&.hide {
			display: none;
		}
	}
}
