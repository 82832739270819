@import 'common/styling/_variables.scss';

.sign-pn {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;

	.pn-spinner-container {
		display: flex;
		justify-content: center;
	}

	.pn-success-message {
		.sign-pn-next-button {
			display: flex;
			flex-direction: row;
			align-content: center;

			button {
				max-width: 335px;
				min-width: 300px;
				margin-bottom: 30px;
			}
		}
	}

	.sign-pn-download-contract-section {
		margin-top: 2rem;
		.sign-pn-approve-checkbox-section {
			display: flex;
			margin-top: 15px;

			.checkbox-container {
				@include checkboxLarge;
			}
		}
	}

	.sign-pn-otp-sign-method-select {
		.col-spinner {
			margin-top: 30px;
		}

		.otp-select-btn-section {
			display: flex;
			flex-direction: column;

			@media screen and (min-width: $screen-md) {
				flex-direction: row;
				justify-content: center;
			}

			.otp-method-btn {
				cursor: pointer;
				display: flex;
				align-items: center;
				position: relative;
				font-size: 1.9rem;
				font-weight: 600;
				background-color: $white;
				color: $primary-warmgray-9;
				border: 1px solid $primary-warmgray-9;
				border-radius: $border-radius-base;
				margin-top: 16px;
				padding: 10px 16px;
				width: 100%;

				@media screen and (min-width: $screen-md) {
					text-align: center;
					height: 120px;
					flex: 1 1 0;
					margin: 16px 8px;
					flex-direction: column-reverse;
					justify-content: center;
					word-break: break-word;
					max-width: 520px;
				}

				img {
					height: 2.5rem;
					margin: 0 1.5rem 0 0.1rem;

					@media screen and (min-width: $screen-md) {
						height: 3rem;
						margin: 10px 0;
					}
				}

				&.active {
					font-weight: 600;
					border: 2px solid $primary-bronze-1;
					color: $primary-warmgray-11;

					&::after {
						content: url('/img/success-icons/tick-bronze-slim.svg');
						height: 2.5rem;
						margin-left: auto;

						@media screen and (min-width: $screen-md) {
							content: none;
						}
					}
				}
			}
		}
		.send-otp-button-section {
			display: flex;
			flex-direction: column;
			align-items: center;
			// margin: 30px 0;

			button {
				max-width: 335px;
				min-width: 300px;
				margin: 30px 0;
			}
		}
	}

	.sign-pn-otp-validate {
		.otp-section-sms-and-email {
			.otp-timer {
				color: $green-md;
				font-size: 25px;
				font-weight: 900;
				text-align: center;
				padding-bottom: 16px;
				padding-top: 8px;

				&.time-out {
					color: $danger;
				}
			}

			.otp-code-entry-section {
				display: flex;
				flex-direction: column;
				margin-top: 1rem;

				.form-control {
					padding: 1.1rem 2rem;
					margin: 32px auto 0 auto;
					max-width: 400px;
					font-size: 1.7rem;
					height: 46px;
					color: #575651;
					border: 1px solid #575651;
					border-radius: $border-radius-small;
					&:focus {
						color: #575651;
						border: 1px solid #de996f !important;
						box-shadow: 0 0 0 0.08rem #de996f;
						outline: 0;
					}
				}

				button {
					width: 100%;
					max-width: 400px;
					margin: 16px auto;
				}

				@media screen and (min-width: $screen-md) {
					flex-direction: row;
					align-items: center;
					justify-content: center;

					.form-control {
						margin: 0;
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
					}

					button {
						min-height: 46px;
						width: 100%;
						margin: 0;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
					}
				}
			}
			@media screen and (min-width: $screen-xl) {
				.otp-timer,
				.otp-information-top,
				.otp-information-bottom {
					margin: 0 5rem;
				}
			}
			.otp-information-bottom.field-note {
				font-size: 1.5rem;
				margin-top: 5px;
			}

			.validate-btn {
				min-height: 46px;
				#loading-spinner {
					transform: translate(0px, -10px);
				}
			}
			.box-info {
				margin-left: 5rem;
				margin-right: 5rem;
				margin-top: 15px;
			}
		}

		.back-section {
			display: flex;
			justify-content: center;

			.back-btn {
				max-width: 335px;
				min-width: 300px;

				@media screen and (min-width: $screen-md) {
					margin: 2.4rem auto;
				}
			}
		}
	}

	.signing-section {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		h6 {
			margin-bottom: 10px;
		}
		.sign-pn-information-section {
			div {
				margin-bottom: 15px;
			}

			&.co-applicant {
				margin-left: auto;
				margin-right: auto;
			}
		}
		.sign-pn-checkbox-section {
			div {
				margin-bottom: 15px;
			}
			
			.checkbox-container {
				@include checkbox;
			}
		}
		.error-message {
			color: #8d191d;
			font-weight: bold;
		}
	}

	.sign-pn-button-section {
		display: flex;
		justify-content: center;
		margin-top: 15px;
		width: 100%;

		button {
			max-width: 335px;
			min-width: 300px;
			margin: 30px 0;
			min-height: 46px;
		}

		.sign-pn-spinner-container {
			display: flex;
			justify-content: center;
		}

		.col-spinner {
			display: flex;
			justify-content: center;
		}
	}

	.payment-protection-insurance {
		margin-top: 15px;
	}

	.traffic-lights-expander {
		margin-top: 3rem;
	}

	&.denmark {
		.success-msg-signing {
			padding: 0 2rem;
		}
	}

	&.finland {
		.pn-response-container {
			.psd2-skipped {
				margin-bottom: 1rem;
			}
		}
	}
}
