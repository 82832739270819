@import 'common/styling/_variables.scss';

.missing-kyc {
	margin-bottom: 30px;

	@media screen and (min-width: $screen-md) {
		margin-bottom: 0;
	}

	.missing-kyc-form {
		margin-top: 3rem; //norway ok
	}

	#main-form {
		.form-group.control-dropdown {
			* {
				cursor: pointer;
			}
			label.control-label {
				all: revert;
				top: 0;
			}

			.dropdown-toggle.btn.btn-default {
				padding-top: 12px;
			}
		}

		.formTooltip {
			display: inline-block !important;
			height: 16px;

			.icon {
				background: url('/img/information-icons/info_bronze.svg')
					no-repeat center;
				background-size: 95%;
				background-position: bottom;
				height: 20px;
				width: 20px;
			}
		}
	}
}
