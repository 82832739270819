@import 'common/styling/_variables.scss';

#login-page {
	.login-bg {
		height: 100vh;
		background-image: url('/img/background-images/woman_hanging_outside_car_window.webp');
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 100vh;

		@media screen and (max-width: $screen-sm) {
			background-position: 45%;
		}
		.logged-out {
			color: #fff;
			font-size: 1.5rem;
		}

		.login-page-container {
			height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			position: relative;
			z-index: 50;
			background-color: rgba(222, 154, 111, 0.185);
			min-height: 100vh;

			.tfbank-logo.login {
				width: 100%;
				max-width: 20rem;
				margin-top: 1.5rem;
				filter: unset;
				@media (min-width: $screen-md) {
					max-width: 195px;
				}
			}
			@media screen and (max-width: $screen-md) {
				flex-direction: column;
			}
		}
	}

	.login-container-header {
		display: flex;
		justify-content: center;
		color: $white;
		margin-top: 1rem;
		margin-bottom: 2rem;
	}

	.login-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0px 0px 20px 10px rgba(97, 97, 97, 0.2);

		@media screen and (max-width: $screen-sm) {
			width: 100%;
			max-width: 340px;
		}

		.login-header {
			width: 100%;
			padding-top: 2rem;

			button {
				position: absolute;
			}
		}

		.login-content {
			margin: 2rem;
		}
	}

	.auth-card-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;

		#circle-spinner {
			margin: 1rem 0;
		}
	}

	.auth-content-container {
		display: flex;
		flex-direction: column;

		.hide {
			display: none;
		}
	}

	.auth-card-header-container {
		margin: 0 auto;

		@media screen and (max-width: $screen-sm) {
			margin-top: 5rem;
		}

		h2,
		h2 * {
			color: $white;
		}
	}

	.auth-card-items-container {
		padding: 0;
		width: 80%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: $screen-sm) {
			flex-direction: column;
		}

		.auth-card {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			border: none;
			box-shadow: 0px 0px 20px 10px rgba(97, 97, 97, 0.2);
			text-align: center;
			width: 250px;
			min-height: 150px;
			max-width: 500px;
			border-radius: 5px;
			background-color: #fff;
			margin: 1rem;
			padding: 2rem;

			@media screen and (max-width: $screen-sm) {
				justify-content: space-evenly;
				min-width: unset;
				margin-left: unset;
				margin-right: unset;
				padding: 0;
			}
		}

		.auth-card-header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 70px;
			margin-top: 1rem;
		}

		.auth-card-content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;

			.auth-card-content-info {
				padding-bottom: 1rem;
				font-weight: 600;
			}
		}

		&.multiple-methods {
			.auth-card {
				justify-content: space-evenly;
				min-height: 140px;

				@media screen and (max-width: $screen-sm) {
					min-height: 120px;
				}

				.authButton-text {
					font-size: 13px;
					margin-top: 1rem;
				}
			}
		}
	}

	.authButton-icon {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.authButton-text {
			font-size: 12px;
			font-weight: 600;
		}

		.bank-id {
			content: url('/img/login-logos/no/bankid-logo.svg');
			width: 6.3rem;
		}

		.signicat {
			content: url('/img/login-logos/fi/signicat-fi-logo2_2.svg');
			width: 19rem;
			height: 6rem;
		}

		.mit-id {
			content: url('/img/login-logos/dk/signicat-dk-logo-mitId.png');
			width: 12.4rem;
		}

		.norwegian-bank-id {
			content: url('/img/login-logos/no/bankid-logo-no.png');
			width: 14rem;
			margin-bottom: 0.6rem;
		}

		.norwegian-bank-id-mobile {
			content: url('/img/login-logos/no/bankid-logo-mobile-no.png');
			width: 14rem;
		}

		.baltic-otp,
		.spain-otp,
		.germany-otp {
			content: url('/img/tf-logos/TF_logo_cropped.png');
			width: 19.5rem;
		}
	}
}
