/* Error fallback container styling for the ErrorFallbackPage */
/* Container wrapping box-info component */
@import 'common/styling/_variables.scss';

#error-fallback-page {
	.background-img {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		overflow: auto;
		min-width: 100%;
		min-height: 100%;
		background-image: url('/img/background-images/woman_hanging_outside_car_window.webp');
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 100vh;
	}
	.logout-container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		.tfbank-logo.logout {
			width: 100%;
			max-width: 20rem;
			margin-top: 1.5rem;
			filter: unset;
			@media (min-width: $screen-md) {
				max-width: 195px;
			}
		}

		.logout-body {
			text-align: center;
			max-width: 350px;
			color: $white;
			.btn-outline::after {
				// This button has an inline SVG as icon, so the default ::after stacks unwanted space without this "content: none" property.
				content: none !important;
			}

			h2 {
				margin-bottom: 1.5rem;
			}
		}
	}
}
