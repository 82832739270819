@import 'common/styling/_variables.scss';

.marketing-consent {
	&.check-box-type {
		.checkbox-container {
			@include checkbox;
		}
	}
}

.marketing-consent-section {
	&.norway {
		margin-top: 30px;
	}
}
