@import 'common/styling/_variables.scss';

.traffic-lights-expander {
	margin-top: 1rem;

	.expander-item {
		margin-top: 0px;
		background-image: linear-gradient(rgba(239, 238, 227, 0.5), #f6f5ee);
		padding-top: 10px;
	}
}

#traffic-lights {
	width: 100%;
	display: flex;
	flex-direction: column;

	h6 {
		text-align: center;
	}

	.traffic-lights-container {
		display: flex;
		flex-direction: row;
	}

	.traffic-lights {
		margin-left: 1rem;
		background-color: $black;
		border: 3px double $stonegray-20;
		width: 40px;
		height: 100px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 8px 5px;
		border-radius: 10px;

		span {
			width: 24px;
			height: 24px;
			border-radius: 100%;
			background-color: $stonegray-10;
			margin: auto auto;
			opacity: 0.5;

			&.active {
				opacity: 1;
				animation-name: pulsatingLight;
				animation-duration: 5s;
				animation-iteration-count: 3;

				&.red {
					border: 2px dotted red;
					box-shadow: 1px 1px 7px 5px rgba(255, 0, 0, 0.8);
					background: red;
					background-image: radial-gradient(brown, transparent);
					background-size: 3px 3px;
				}

				&.yellow {
					border: 2px dotted yellow;
					box-shadow: 1px 1px 7px 3px rgba(255, 255, 0, 0.8);
					background: yellow;
					background-image: radial-gradient(
						rgb(248, 186, 70),
						transparent
					);
					background-size: 3px 3px;
				}

				&.green {
					border: 2px dotted rgb(0, 128, 0);
					box-shadow: 1px 1px 7px 5px rgba(0, 128, 0, 0.6);
					background: rgb(0, 128, 0);
					background-image: radial-gradient(
						rgb(0, 210, 35),
						transparent
					);
					background-size: 3px 3px;
				}
			}
		}
	}

	.traffic-lights-information {
		margin-left: 1rem;

		p {
			font-size: 15px;
			margin-right: 1rem;
		}
	}
}

@keyframes pulsatingLight {
	from {
		opacity: 1;
	}

	50% {
		opacity: 0.6;
	}

	to {
		opacity: 1;
	}
}
