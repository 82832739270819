@import 'common/styling/_variables.scss';

.upload-documents {
	display: flex;
	flex-direction: column;

	.upload-documents-dropzone {
		margin-top: 20px;

		.upload-documents-dropzone-items {
			display: flex;
			flex-direction: column;

			@media screen and (min-width: $screen-lg) {
				flex-direction: row;
				flex-wrap: wrap;

				> * {
					flex: 1 1 0;
					min-width: 400px;
				}

				.dropzone-item-0,
				.dropzone-item-2,
				.dropzone-item-4 {
					margin-right: 10px;
				}

				.dropzone-item-1,
				.dropzone-item-3,
				.dropzone-item-5 {
					margin-left: 10px;
				}
			}

			.expander-item {
				justify-content: space-between;

				.read-more-expander-header {
					font-weight: 600;
					color: $primary-warmgray-11;
				}
			}
			hr {
				margin-top: 5px;
				border-color: $primary-warmgray-11;

				@media screen and (min-width: $screen-md) {
					display: none;
				}
			}

			.dropzone-outer {
				margin-top: 10px;
				margin-bottom: 20px;
				.drop-zone {
					border: 1px dashed #dadada;
					background-color: #f8f8f8;
					color: $black;
					padding: 40px;
					width: 100%;
					height: 200px;

					img {
						max-height: 5rem;
					}
				}

				.upload-documents-selected-documents {
					overflow-wrap: anywhere;
				}

				.prev-upload-items {
					background-color: $cream-dark;
					font-size: 1.2rem;
					position: relative;
					min-height: 58px;
					padding-bottom: 0.3rem;

					.prev-upload-items-docs {
						all: unset;
						width: 100%;
						overflow-wrap: anywhere;
						cursor: pointer;
						font-size: 17px;
						align-content: center;
						margin-bottom: 0.3rem;

						&:hover {
							color: $stonegray-20;

							img {
								filter: brightness(1.1) opacity(90%);
							}
						}
					}

					img {
						height: 20px;
					}
				}

				.dropzone-outer-bottom-text {
					margin-top: 10px;
					font-size: 15px;
				}
			}
		}

		.upload-documets-dropzone-buttons {
			display: flex;
			flex-direction: column;
			align-items: center;

			button {
				max-width: 335px;
				min-width: 300px;
				margin: 15px 0 30px 0;
			}

			.document-upload-button {
				min-height: 46px;

				.document-upload-spinner-container {
					display: flex;
					justify-content: center;

					#loading-spinner {
						transform: translate(0px, -10px);
					}
				}
			}

			.document-upload-success {
				width: 100%;
			}
		}
	}

	.upload-documents-next-button {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 30px;
		margin-bottom: 30px;

		@media screen and (min-width: $screen-lg) {
			margin-bottom: 0;
		}

		button {
			width: 100%;
			max-width: 335px;
			min-width: 300px;
		}
	}

	.tooltip-tip .tooltip-text {
		@media only screen and (max-width: $screen-md) {
			left: -480%;

			.arrow-down {
				left: 92%;
			}
		}
	}

	#circle-spinner {
		margin-bottom: 15px;
	}

	&.denmark {
		.btn-eskat-link {
			margin-top: 20px;
		}

		.upload-documents-description-top {
			margin-bottom: 1rem;
		}

		.document-upload-success {
			margin-bottom: 3rem;
		}

		.upload-documents-next-button {
			margin-bottom: 30px;
			margin-top: 0;

			button {
				margin-left: auto;
				margin-right: auto;
			}
			&.active {
				display: flex;
			}
			&.inactive {
				display: none;
			}
		}
	}

	&.norway {
		margin-bottom: 30px;

		.upload-documents-description-top {
			button {
				padding: 0;
				text-align: left;
			}
		}

		.upload-documents-dropzone {
			.upload-documents-dropzone-items {
				@media screen and (min-width: $screen-lg) {
					flex-direction: row;
					flex-wrap: wrap;

					> * {
						flex: 1 1 0;
						min-width: 150px;
					}

					.dropzone-item-0,
					.dropzone-item-1 {
						margin-right: 10px;
					}

					.dropzone-item-1,
					.dropzone-item-2 {
						margin-left: 10px;
					}

					.dropzone-item-2 {
						margin-right: 0;
					}
				}
			}

			.upload-documents-next-button {
				margin-bottom: 30px;
				margin-top: 0;
			}
		}
		.upload-documents-back-button-section {
			display: flex;
			flex-direction: column;
			align-items: center;

			button {
				max-width: 335px;
				min-width: 300px;
				margin-bottom: 30px;
			}
		}
	}
}
