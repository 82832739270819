/* Consider caution before editing this file */
@import 'common/styling/_variables.scss';

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	font-size: 1.7rem;

	/* General styling for all type fonts applied*/
	h1 {
		font-size: $font-size-h1;
		font-weight: 600;
	}

	h2 {
		font-size: $font-size-h2;
		font-weight: 600;
	}

	h3 {
		font-size: $font-size-h3;
		font-weight: 600;
	}

	h4 {
		font-size: $font-size-h4;
		font-weight: 600;
	}

	h5 {
		font-size: $font-size-h5;
		font-weight: 600;
	}

	h6 {
		font-size: $font-size-h6;
		font-weight: 600;
	}

	a {
		font-size: $font-size-body;
		@include linkWhite;

		&.link-bronze {
			@include linkBronze;
		}

		&.link-lightgray {
			@include linkGray2;
		}

		&.link-darkgray {
			@include linkGray11;
		}
	}

	/* Basic button styling applied */
	.btn {
		@include btnBase;

		&:focus,
		&:active,
		.show > .dropdown-toggle:focus {
			box-shadow: none;
		}

		&.btn-primary,
		&.btn-default {
			@include btnPrimaryBronze;
			&:not(.form-control):not(.dropdown-toggle) {
				@include ripple(#eea87e, $primary-bronze-1, 399ms);
			}
		}

		&.btn-primary-darkgray {
			@include btnPrimaryDarkGray;
		}

		&.btn-primary-lightgray {
			@include btnPrimaryLightGray;
		}

		&.btn-outline {
			@include btnOutlineBronze;
		}

		&.btn-outline-darkgray {
			@include btnOutlineDarkGray;
		}

		&.btn-outline-lightgray {
			@include btnOutlineLightGray;
		}

		&.btn-outline-danger {
			@include btnOutlineDanger;
		}

		&.btn-secondary {
			@include btnSecondaryDarkGray;
		}

		&.btn-secondary-light {
			@include btnSecondaryLightGray;
		}
	}
}
