@import 'common/styling/_variables.scss';

#not-found-page {
	position: relative;
	min-height: 100vh;
	max-width: 1440px;
	margin: 0 auto;
	width: 100%;
	background-color: $cream-rich;
	display: flex;
	flex-direction: column;

	.not-found-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.not-found-head {
		span {
			font-size: 10rem;
		}

		svg {
			font-size: 8rem;
			margin: 0 2rem;
			position: relative;
			display: inline-block;
			opacity: 1;
			transition: all 250ms ease-in-out;
			animation: float 6s ease-in-out infinite;
			filter: drop-shadow(1rem 3rem 1rem $primary-warmgray-5);
		}
	}

	&.x-mas svg {
		filter: drop-shadow(1rem 3rem 1rem rgba(141, 25, 29, 0.3));
	}
}

@keyframes float {
	0% {
		transform: rotate(0deg) translate(0, 0) opacity(1);
		opacity: 1;
	}

	33% {
		transform: rotate(15deg) translate(0, 1rem);
		opacity: 1;
	}

	66% {
		transform: rotate(10deg) translate(0, -40rem);
		opacity: 0.3;
	}

	100% {
		transform: rotate(0) translate(0, 0);
		opacity: 1;
	}
}

/* Snow animation for december */
.snow-wrap {
	z-index: 0;
	position: absolute;
	overflow-y: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.snow,
.snow::after,
.snow::before {
	z-index: -1;
	content: '';
	position: absolute;
	top: -650px;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: radial-gradient(
			4px 4px at 100px 50px,
			rgb(219, 218, 218),
			transparent
		),
		radial-gradient(6px 6px at 200px 150px, rgb(219, 218, 218), transparent),
		radial-gradient(3px 3px at 300px 250px, rgb(219, 218, 218), transparent),
		radial-gradient(4px 4px at 400px 350px, rgb(219, 218, 218), transparent),
		radial-gradient(6px 6px at 500px 100px, rgb(219, 218, 218), transparent),
		radial-gradient(3px 3px at 50px 200px, rgb(219, 218, 218), transparent),
		radial-gradient(4px 4px at 150px 300px, rgb(219, 218, 218), transparent),
		radial-gradient(6px 6px at 250px 400px, rgb(219, 218, 218), transparent),
		radial-gradient(3px 3px at 350px 500px, rgb(219, 218, 218), transparent);
	background-size: 650px 650px;

	animation: snowAnimation 10s linear;
	animation-iteration-count: infinite;
}

.snow::after {
	margin-left: -250px;
	opacity: 0.5;
	filter: blur(2px);
	animation-direction: reverse;
	animation-duration: 6s;
}

.snow::before {
	margin-left: -350px;
	opacity: 0.7;
	filter: blur(1px);
	animation-direction: reverse;
	animation-duration: 9s;
}

@keyframes snowAnimation {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(650px);
	}
}
