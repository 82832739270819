@import 'common/styling/_variables.scss';

.card-applicationpage {
	min-height: 100vh;

	.tfbank-logo-header {
		max-width: 220px;
	}
	.sign-out .btn-primary {
		max-width: 9rem;
		@media (max-width: 576px) {
			max-width: 6rem;
			font-size: 0.7rem;
		}
	}

	.footer {
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
	}

	.heading {
		.description {
			text-align: center;
		}
	}
	.de-page-color .btn {
		min-width: 40%;
	}
	&.auth {
		.heading {
			max-width: 600px;
			margin: auto;
		}
		.description {
			font-size: 16px;
			margin-top: 0.3rem;
			margin-bottom: 0.5rem;
		}
		.col-spinner {
			min-height: unset;
			margin-bottom: 0;
			margin-top: 1.3rem;
			#circle-spinner {
				height: 30px;
				width: 30px;
			}
		}
		.sign {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: 500px;
			// input {
			// 	max-width: 400px;
			// }
		}

		.form-control {
			padding: 1.1rem 1rem;
			font-size: 16px;
		}

		.validation-error {
			color: $danger;
		}
	}
	.rejected {
		margin-bottom: 27rem;
	}
	.alert.alert-danger {
		margin-bottom: 27rem;
	}
	.col-spinner {
		min-height: 70vh;
	}
	.read-more {
		@media (max-width: 768px) {
			justify-content: center;
			flex-direction: row;
		}
		span:nth-child(1),
		span:nth-child(3) {
			@media (max-width: 768px) {
				width: 20%;
			}
		}
		span:nth-child(2) {
			max-width: 450px;
			color: #fff;
			text-align: center;
			max-width: 500px;
		}
	}
	.cards-container {
		display: flex;
		@media (max-width: 768px) {
			flex-direction: column;
		}
		div:first-child {
			@media (max-width: 768px) {
				margin: 0;
			}
		}
		.tfbank-card-container {
			.imageContainer {
				width: 300px;
				max-width: 300px;
				max-height: 198px;
				img {
					width: 300px;
					max-width: 300px;
				}
			}
			.text {
				margin: auto;
				max-width: 350px;

				@media screen and (min-width: $screen-md) {
					max-width: 768px;
				}
			}
		}
	}
	.heading-two {
		margin-bottom: 39px;
	}
	.card-section-text {
		margin: 2rem 0 1.5rem 0;
	}
	.tfbank-card-container {
		margin-right: 3rem;
	}
	.box-amount {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0;
		margin-top: 5px;
		height: 187px;
		width: 293px;
		border-radius: 9px;
		color: $primary-bronze-1;
		border: none;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		line-height: 30px;
		letter-spacing: -0.015em;
		@media (max-width: 768px) {
			height: 100px;
			margin-top: 1.1rem;
		}
		div {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		span:first-child {
			line-height: 2rem;
			font-size: 1.4rem;
			@media (max-width: 768px) {
				font-size: 1.5rem;
				line-height: 3rem;
			}
		}
		span:nth-child(2) {
			font-size: 2.5rem;
			line-height: 3rem;
			@media (max-width: 768px) {
				font-size: 2rem;
			}
		}
	}

	.btn-primary {
		max-width: 300px;
		min-height: 35px;

		@media screen and (max-width: $screen-sm) {
			max-width: 100%;
		}
	}
	.upload-doc {
		@media (min-width: $screen-md) {
			.identification-select {
				.d-flex {
					width: 30%;
				}
				.form-select {
					width: 40%;
				}
			}
		}
		@media (max-width: $screen-md) {
			.identification-select {
				flex-direction: column;
				width: 100%;
			}
		}

		.upload-documents-selected-documents {
			overflow-wrap: anywhere;
		}

		h6 {
			font-weight: bold;
		}

		.col-12 {
			padding-left: 0;
			padding-right: 0;
		}
		.form-select {
			background-color: $white;
			padding: 0.5rem;
			border-radius: 5px;
			border: none;
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
			max-height: 3.3rem;
			@media (max-width: $screen-md) {
				width: 100%;
				margin-bottom: 2rem;
			}
			option {
				color: $primary-warmgray-11;
				&:hover {
					background-color: $white;
				}
			}
		}
		.checkbox-container {
			display: block;
			position: relative;
			padding-left: 50px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			.checkmark-span {
				font-size: small;
				padding-top: 0.5rem;
				@media (min-width: $screen-md) {
					display: flex;
					width: 50%;
				}
			}
		}
		.checkbox-container input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 32px;
			width: 32px;
			background-color: $white;
			border-radius: 0.3rem;
			transition: all 123ms ease-in-out;
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
		}
		.checkbox-container input:checked ~ .checkmark {
			background-image: url('/img/form-field-icons/tick/tick_bronze.svg');
			border: 2px solid $primary-warmgray-11;
			background-size: 27px 27px;
			//transform: scale(0.9, 0.9);
			transition: all 123ms ease-in-out;
		}
		.checkbox-container input:not(:checked) ~ .checkmark {
			background-size: 27px 25px;
			animation: scale 499ms;
			transform-origin: bottom;
			transition: all 123ms ease-in-out;
		}
		.checkbox-container input:disabled {
			opacity: 0.2;
		}
		.dropzone-outer {
			margin-bottom: 2rem;

			@media (max-width: $screen-lg) {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.drop-zone {
				padding: 2.5rem 4rem 4rem 4rem;
				height: 250px;
				width: 100%;
				background-color: $white;
				border: 1px solid $primary-warmgray-11;
				max-width: 450px;

				@media (max-width: 576px) {
					padding: 1.3rem 2rem 4rem 2rem;
					height: 275px;
				}
				b {
					font-size: 1.3rem;
					margin-bottom: 0.5rem;
				}
				img {
					height: 60px;
				}
			}
		}
		.text-red {
			color: $danger;
		}
		.activationslip-example {
			border: 2px solid $primary-warmgray-11;
			position: relative;
			background-color: $white;
			padding-top: 3rem;
			@media (max-width: 576px) {
				border: unset;
				padding-top: 0;
				background-color: unset;
			}
			.example-text {
				position: absolute;
				top: 15px;
				font-weight: bold;
				color: $primary-warmgray-11;
				text-align: center;
				@media (max-width: 576px) {
					background-color: unset;
					top: -25px;
				}
			}
			img {
				max-width: 300px;
				@media (max-width: 576px) {
					width: 300px;
					height: 198px;
					margin-top: 2rem;
				}
			}
		}

		#circle-spinner {
			width: 15px;
			height: 15px;
		}
	}
	.section-app {
		.app-links {
			display: flex;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
	}
	&.card-applicationpage.auth .de-page-color {
		padding-bottom: 18rem;
		@media (max-width: $screen-xl) {
			padding-bottom: 12rem;
		}
		@media (max-width: 768px) {
			padding-bottom: 11rem;
		}
	}
	.de-page-color {
		background-color: $cream-rich;
		max-width: 1440px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		padding-top: 90px;
		min-height: 70vh;
		padding-bottom: 11rem;
		@media (max-width: 768px) {
			padding-top: 60px;
		}
		@media (max-width: 768px) {
			padding-bottom: 14rem;
		}
		.intro-section {
			padding: 0 0 3rem;
			background-color: $cream-rich;
		}
		.idnow-section {
			padding: 3rem 0 3rem;
			background-color: $cream-rich;
			@media (max-width: 768px) {
				padding: 2rem 0 2rem;
			}
		}
		.activationslip-section {
			padding: 3rem 0 0;
			background-color: $cream-rich;
		}
		#sign-section-de {
			.btn.btn-default {
				max-width: 500px;
				margin: 3rem auto !important;
			}
		}
	}
	.prev-upload-items {
		background-color: $cream-rich;
		font-size: 0.9rem;
		position: relative;
		min-height: 58px;
		max-width: 450px;

		img {
			height: 20px;
			margin-bottom: 10px;
		}
	}

	.file-name {
		overflow: hidden;
	}

	.prev-upload-items-docs {
		all: unset;
		padding: 0.75rem 0 0.75rem 0;
		width: 100%;
		cursor: pointer;
		font-size: 1.7rem;

		&:hover {
			color: $primary-warmgray-11;
		}
	}
	h5 {
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		line-height: 38px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: -0.015em;
		justify-content: center;
	}
	hr {
		display: none;
	}
}

.expander-item span {
    background-color: grey;
    height: 1px;
    margin-top: 5px;
    width: 45%;
}

.id-now-expander .read-more-expander-header {
    color: #fff;
    max-width: 450px;
    max-width: 500px;
    text-align: center;
	background-color: rgb(88, 88, 87);
	border-radius: 20px;
    color: #fff;
    padding: 2px;
    text-align: center;
    width: 200px;
}