@import 'common/styling/_variables.scss';

#footer-logged-out {
	position: relative;
	bottom: 1rem;
	margin-top: 2rem;
	left: 0;
	overflow: auto;
	text-align: center;
	min-width: 100%;
	z-index: 9090;
	color: $white;
	overflow: hidden;

	#footer-copyright {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		color: $white;

		img {
			height: 5rem;
			padding: 1rem;
		}
	}

	.row {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin: 0;
	}

	@media only screen and (max-width: $screen-lg-max) {
		justify-content: center !important;
	}

	.col {
		#footer-contact {
			margin-top: -5px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			a {
				text-decoration: none;
				color: $white;
			}

			div {
				display: flex;
				align-items: center;
			}

			.footer-contact-phone-number {
				padding-right: 0.5rem;

				a:nth-child(even) {
					padding-left: 0.5rem;
				}
			}
		}
	}

	@media only screen and (min-width: $screen-md) {
		.col {
			#footer-contact {
				flex-direction: row;
			}
		}
	}
}

.de {
	.card-applicationpage{
		#footer-logged-out {
			color: #575651;
			#footer-copyright {
				color: #575651;
				img {
					display: none;
				}
			}
		}
	}
}
