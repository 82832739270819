/* As the Modals aren't children of App.tsx we need to set the general styling separately */
@import 'common/styling/_variables.scss';

.modal {
	/* General styling for all type fonts applied*/
	h1 {
		font-size: $font-size-h1;
		font-weight: 800;
	}

	h2 {
		font-size: $font-size-h2;
		font-weight: 800;
	}

	h3 {
		font-size: $font-size-h3;
		font-weight: 600;
	}

	h4 {
		font-size: $font-size-h4;
		font-weight: 600;
	}

	h5 {
		font-size: $font-size-h5;
		font-weight: 600;
	}

	h6 {
		font-size: $font-size-h6;
		font-weight: 600;
	}

	a {
		font-size: $font-size-body;
		@include linkWhite;

		&.link-bronze {
			@include linkBronze;
		}

		&.link-lightgray {
			@include linkGray2;
		}

		&.link-darkgray {
			@include linkGray11;
		}
	}

	/* Basic button styling applied */
	.btn {
		@include btnBase;

		&.btn-primary,
		&.btn-default {
			@include btnPrimaryBronze;
		}

		&.btn-primary-darkgray {
			@include btnPrimaryDarkGray;
		}

		&.btn-primary-lightgray {
			@include btnPrimaryLightGray;
		}

		&.btn-outline {
			@include btnOutlineBronze;
		}

		&.btn-outline-darkgray {
			@include btnOutlineDarkGray;
		}

		&.btn-outline-lightgray {
			@include btnOutlineLightGray;
		}

		&.btn-outline-danger {
			@include btnOutlineDanger;
		}

		&.btn-secondary {
			@include btnSecondaryDarkGray;
		}

		&.btn-secondary-light {
			@include btnSecondaryLightGray;
		}
	}

	.modal-body {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 1.5rem 0;
	}

	.close {
		font-size: 3.5rem;
		padding-top: 0;
		padding-bottom: 0;
	}

	.modal-title {
		font-size: $font-size-h2;
		font-weight: 600;
	}

	.modal-content {
		border: none;
		padding: 1rem;
		.modal-header {
			border-bottom: none;
		}
		.modal-footer {
			display: flex;
			justify-content: space-between;
			border-top: none;
		}
	}

	.modal-footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
}
