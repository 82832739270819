@import 'common/styling/_variables';

.maintenance-window {
	background-color: $cream-rich;
	color: $primary-warmgray-11;
	box-shadow: $shadow-1;
	position: fixed;
	width: 80%;
	border-radius: 5px;
	top: 100px;
	right: 10%;
	z-index: 50;

	.fa-times {
		width: 20px !important;
		height: 20px;
		color: $primary-warmgray-11;
	}

	div {
		flex-direction: row;
		align-items: center;
	}

	@media screen and (max-width: $screen-md) {
		width: 100%;
		height: fit-content;
		right: 10;
		bottom: 0;

		div {
			flex-direction: column;
			align-items: unset;
		}
	}

	.warning {
		content: url('/img/error-icons/error_warning_darkred.svg');
		height: 25px;
		width: 25px;
	}
}

.nonAuthMaintenance {
	.maintenance-window {
		width: 80%;
	}
}

.authMaintenance {
	.maintenance-window {
		width: 60%;
		top: 100px;
		right: 20%;
		z-index: 50;
	}
}
