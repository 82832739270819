@import 'common/styling/_variables.scss';

.loan-application-block,
.card-application-block {
	.province-input-box {
		width: 100%;
	}

	/* Multistep item styling */
	.personal-information,
	.address-and-accommodation,
	.employment-and-income,
	.expenses {
		margin-bottom: 15px;

		.container-fluid.fieldset {
			margin-bottom: 15px;
		}
	}

	.psd2-section {
		display: flex;
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;
		padding: 30px 0;

		.psd2-header {
			font-size: 30px;
			font-weight: 600;
			text-align: center;
		}

		.psd2-description {
			font-size: 15px;
			padding-bottom: 30px;
		}

		iframe {
			width: 100%;
			height: 250px;
		}

		@media screen and (min-width: $screen-md) {
			max-width: 670px;
		}
	}

	.address-and-accommodation-button-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.address-and-accommodation-button {
			max-width: 335px;
			min-width: 300px;
		}
	}

	.expenses {
		.form-group.control-radio {
			.description {
				font-size: 17px !important;
			}
		}
		.col-spinner {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}

	.financial-data {
		display: flex;
		flex-direction: column;
		align-items: center;

		.submit-application-button-section {
			display: flex;
			flex-direction: row;
			align-content: center;

			button {
				max-width: 335px;
				min-width: 300px;
				margin-bottom: 30px;
				display: none;
				visibility: hidden;
				opacity: 0;
				transition: visibility 0.3s, opacity 0.3s ease-in-out;

				&.show {
					display: block;
					visibility: visible;
					opacity: 1;
				}
			}
		}

		#afterbanks-error-container {
			display: none;
			visibility: hidden;
			opacity: 0;
			transition: visibility 0.3s, opacity 0.3s ease-in-out;

			&.show {
				display: block;
				visibility: visible;
				opacity: 1;
			}
		}

		.col-spinner {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}

	.error-box-container {
		margin-top: 1rem;
	}

	.hide {
		visibility: hidden;
	}
}
