@import 'common/styling/_variables.scss';

.identification {
	margin-bottom: 3rem;
	// display: flex;
	// justify-content: center;

	.col-spinner {
		margin-top: 3rem;
	}

	#video {
		margin-left: auto;
		margin-right: auto;

		.eid-button-primary {
			@include btnPrimaryBronze;
		}

		.eid-svg-container svg {
			filter: invert(0%) sepia(79%) saturate(492%) hue-rotate(87deg)
				brightness(94%) contrast(93%);
		}

		.eid-loading div {
			border: 5px solid $primary-bronze-1;
		}
	}

	.videoid-next-button {
		display: flex;
		flex-direction: row;
		align-content: center;

		button {
			max-width: 335px;
			min-width: 300px;
			margin-bottom: 30px;
		}
	}

	.error-box-container {
		display: flex;
		justify-content: center;
	}
}
