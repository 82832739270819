@import 'common/styling/_variables.scss';

.popover {
	font: inherit;
	border: none !important; /* the !important is needed to over write the bootstrap styling */

	.popover-body {
		background-color: $primary-bronze-1;
		color: $primary-warmgray-2;
		font-size: 1.5rem;
		border-radius: 4px;
		border: 1px solid $primary-bronze-1;
		padding: 15px;
		text-align: left;
	}

	.arrow::after,
	.arrow::before {
		border-top-color: $primary-bronze-1 !important;
	}
}
