@import 'common/styling/_variables.scss';

.confirm-pep-modal {
	span {
		display: flex;
		flex-direction: column;
		padding-bottom: 1rem;
	}

	.btn {
		min-width: 15rem;
	}

	@media screen and (max-width: $screen-sm) {
		.modal-footer {
			flex-direction: column;
		}

		.btn {
			margin-bottom: 1.5rem;
		}
	}
}
