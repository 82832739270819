@import 'common/styling/_variables.scss';

#skeleton-loader {
	display: flex;
	width: 100%;
	padding: 1rem;
	height: auto;

	.loading-block {
		display: inline-block;
		min-height: 17rem;
		position: relative;
		overflow: hidden;
		background-color: $cream-dark;
		border-radius: 0.25rem;

		&::after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transform: translateX(-100%);
			background-image: linear-gradient(
				90deg,
				rgba(#fff, 0) 0,
				rgba(#fff, 0.2) 20%,
				rgba(#fff, 0.5) 60%,
				rgba(#fff, 0)
			);
			animation: shimmer 2s infinite;
			content: '';
		}

		@keyframes shimmer {
			100% {
				transform: translateX(100%);
			}
		}
	}
}
