.heading {
	.title {
		margin: 0 15px 15px;
		padding: unset;
		flex: unset;
		text-align: center;
		padding-bottom: 1rem;
		font-size: 2rem;
		font-weight: 600;
		color: #575651;
		border-bottom: none;
		font-family: 'Raleway', Arial;
	}
	.description {
		padding: 0 15px 15px;
		text-align: center;
	}
}

.de {
	.heading {
		.title {
			font-size: 32px;
		}
	}
}
