@import 'common/styling/_variables.scss';

.loan-offer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.col-spinner {
		margin-top: 3rem;
		margin-bottom: 30px;
	}

	.loan-offer-next-button {
		margin-top: 15px;

		@media screen and (max-width: $screen-md) {
			margin-bottom: 30px;
		}
		button {
			max-width: 335px;
			min-width: 300px;
			min-height: 46px;
		}
	}

	.offer-illustration-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 16rem;
		width: 16rem;
		background-color: $white;
		border-radius: 50%;
		padding: 1rem 0;
		margin: 2rem 0;

		.amount {
			font-size: 3rem;
			font-weight: 700;
			color: $primary-bronze-1;
		}

		img {
			max-width: 8rem;
		}
	}

	.sign-pn-spinner-container {
		display: flex;
		justify-content: center;

		#loading-spinner {
			transform: translate(0px, -10px);
		}
	}
}
