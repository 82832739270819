@import 'common/styling/_variables.scss';

#callback-page {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: $cream-dark;
	width: 100%;

	img {
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 20rem;
		margin-top: 1.5rem;
		filter: unset;
		@media (min-width: $screen-md) {
			max-width: 195px;
		}
	}

	.error-container {
		max-width: 500px;
		min-width: 300px;
		box-shadow: 5px 5px 5px rgba($color: $primary-warmgray-9, $alpha: 0.2);

		#error-box {
			background-color: $cream-rich;
		}
	}
}
