@import 'common/styling/_variables.scss';

.sign-psd2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-bottom: 15px;

	&.norway {
		margin-top: 0;
	}

	&.estonia {
		display: flex;
		align-items: center;

		.sign-psd2-description {
			margin-top: 0;
			margin-bottom: 2rem;
		}
	}

	#frameResponse {
		max-width: 95%;
	}

	#arcopayContainer {
		margin-bottom: 2rem;
	}

	.box-info.success {
		width: 100%;
		max-width: 100%;
	}

	.error-box-container {
		margin-top: 15px;
		width: 100%;
		max-width: 100%;

		.box-info.fail {
			max-width: 100% !important;
		}
	}

	.psd2-iframe-container:last-child {
		width: 100%;
	}

	.sign-psd2-radio-button {
		display: flex;
		flex-direction: column;
		width: 100%;

		.description {
			margin-bottom: 1rem;
		}

		.radio-buttons {
			display: flex;

			.radio-button {
				display: flex;
				flex-direction: row;
				padding-right: 3rem;

				&:hover {
					cursor: pointer;
				}

				input[type='radio'] {
					align-items: center;
					cursor: pointer;
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					margin: 0 1rem 0 0.75rem;
					width: 25px;
					height: 25px;
					min-width: 25px;
					max-width: 25px;
					background-color: $white;
					color: $primary-bronze-1;
					border: 1px solid $primary-warmgray-11;
					border-radius: 50%;
					display: grid;
					place-content: center;
				}

				input[type='radio']::before {
					content: '';
					width: 1.5rem;
					height: 1.5rem;
					border-radius: 50%;
					transform: scale(0);
					transition: 120ms transform ease-in-out;
					box-shadow: inset 1em 1em $primary-bronze-1;
				}

				input[type='radio']:checked::before {
					transform: scale(1);
				}
			}
		}
	}

	.sign-psd2-description {
		margin: 1rem 0 2rem 0;
	}

	.skip-psd2-description {
		font-style: italic;
		color: $primary-warmgray-9;
	}

	.sign-psd2-section {
		display: flex;
		flex-direction: column;
		align-content: flex-start;

		span {
			margin-bottom: 1rem;
		}
	}

	.sign-psd2-button-container {
		margin-top: 15px;
		margin-bottom: 15px;
		display: flex;
		justify-content: center;

		button {
			max-width: 335px;
			min-width: 300px;
		}
	}

	.sign-psd2-next-button-container {
		display: flex;
		justify-content: center;
		margin-top: 15px;
		margin-bottom: 30px;
		width: 100%;

		button {
			max-width: 335px;
			min-width: 300px;
		}
	}

	.btn-link-gray-11 {
		color: $primary-warmgray-11;
		text-decoration: underline;
		margin: 0px;
		padding: 3px !important;

		&.resume-psd2-button {
			font-weight: 600;
			text-decoration: none;
			color: $primary-bronze-1;
		}
	}

	.box-info {
		margin-bottom: 1.5rem;
	}
}
