@import 'common/styling/_variables.scss';

.loan-application-header {
	.read-more-expander {
		.box-info.info {
			font-size: 16px;
		}
		&.content-top.mobile {
			.expander-item {
				background-color: #ece9e1;
				margin-top: 0;
				padding-bottom: 15px;
			}
			.box-info.info {
				padding-bottom: 10px;
				&:before {
					top: 55px;
				}
			}
		}
	}
}

.no {
	.loan-application-header {
		.read-more-expander {
			.box-info.info {
				margin-top: 10px;
			}
		}
	}
}
