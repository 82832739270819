@import 'common/styling/_variables.scss';

#multistep {
	.multistep-container {
		align-items: center;

		.multistep-header {
			margin: 10px auto auto auto;
			text-align: center;
		}

		.multistep-content {
			width: 100%;

			@media screen and (min-width: $screen-md) {
				width: 80%;
			}
		}
	}

	.progress-container {
		display: flex;
		padding: 2rem 0;
		position: relative;

		@media (min-width: $screen-md) {
			padding: 20px;
		}

		&::before {
			content: ' ';
			background-color: $primary-bronze-1;
			position: absolute;
			z-index: 0;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
		}

		.bg-info {
			background-color: $primary-warmgray-11 !important;
		}

		.progress {
			width: 100%;
			height: 1rem;
			margin: 0 10px;
			border-radius: $border-radius-base;
			background-color: $primary-warmgray-2;
			z-index: 1;

			.progress-bar:not([aria-valuenow='1']) {
				transform: skewX(-45deg) translateX(-5px);
			}
		}

		&.dark {
			&::before {
				background-color: $primary-warmgray-11;
			}

			.bg-info {
				background-color: $primary-bronze-1 !important;
			}
		}

		&.rounded {
			&::before {
				border-radius: 0.4rem;
			}
		}
	}

	.pagination-container {
		display: flex;
		flex-direction: column-reverse;

		align-items: center;

		:last-child {
			margin-bottom: 15px;
		}

		button {
			max-width: 335px;
			min-width: 300px;
			margin: 0 auto;
		}
	}
}
