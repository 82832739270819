@import 'common/styling/_variables.scss';

.credit-card-offer-header {
	.top-header {
		text-align: center;
		margin-top: 1rem;

		@media screen and (min-width: $screen-md) {
			text-align: left;
		}
	}

	.credit-card-offer-box {
		display: flex;
		flex-direction: column;
		align-items: center;

		.credit-card-offer-box-head {
			text-align: center;
			margin-bottom: 15px;

			h3 {
				font-weight: 600;
			}

			h2 {
				font-weight: 700 !important;
				color: $primary-bronze-1 !important;
			}

			.credit-card-offer-box-information-text {
				&.show {
					opacity: 1;
					visibility: visible;
					transition: visibility 0.3s, opacity 0.3s ease-out;
				}

				&.hide {
					opacity: 0;
					visibility: hidden;
					transition: visibility 0.3s, opacity 0.3s, ease-out;
				}
			}
		}

		.credit-card-offer-box-image {
			margin: 15px 30px;

			.credit-card-gold {
				max-width: 100%;
			}
		}

		@media screen and (min-width: $screen-md) {
			flex-direction: row;
			background-color: $cream-dark;

			.credit-card-offer-box-head {
				flex: 1 1 50%;
			}

			.credit-card-offer-box-image {
				flex: 1 1 50%;
				display: flex;
				justify-content: center;
				margin: 30px auto;

				img {
					max-height: 160px;
				}
			}
		}
	}

	.credit-card-offer-table {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-top: 20px;
		font-size: 15px;

		.credit-card-offer-item {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			div:first-child {
				color: $primary-warmgray-11;
			}

			.credit-card-offer-item-information {
				padding-right: 20px;
				display: flex;
			}
			.credit-card-offer-item-value {
				padding-left: 20px;
				font-weight: 600;
			}
		}
		@media screen and (min-width: $screen-lg) {
			flex-direction: row;
			border: 1px solid $primary-warmgray-5;
			border-radius: $border-radius-base;
			padding: 20px;
			text-align: center !important;

			.credit-card-offer-item {
				flex-direction: column;

				.credit-card-offer-item-information {
					padding-right: 0;
				}
				.credit-card-offer-item-value {
					padding-left: 0;
				}
			}
		}
	}
}
