@import 'common/styling/_variables.scss';

.loan-offer-header {
	.top-header {
		text-align: center;

		@media screen and (min-width: $screen-md) {
			text-align: left;
		}
	}

	.loan-offer-box {
		display: flex;
		flex-direction: column;

		.loan-offer-box-amount {
			margin-top: 10px;
			background-color: #f6f5ee;
			border: 1px solid $primary-warmgray-5;
			padding: 15px 10px;
			border-radius: $border-radius-base;
			text-align: center;
			flex: 1 1 auto;

			span:first-child {
				color: $primary-warmgray-11;
				font-size: 1.5rem;
			}
			span:nth-child(3),
			span:nth-child(2) {
				color: #de996f;
				font-size: 2rem;
				font-weight: 600;
			}
		}

		.loan-offer-box-installment {
			margin-top: 10px;
			background-color: #857f7b;
			color: $white;
			padding: 15px 10px;
			border-radius: $border-radius-base;
			text-align: center;
			flex: 1 1 auto;

			span:nth-child(3) {
				font-size: 2rem;
				font-weight: 600;
			}
			span:first-child {
				font-size: 1.5rem;
			}
		}
		@media screen and (min-width: $screen-md) {
			flex-direction: row;
			margin-top: 1rem;

			.loan-offer-box-amount {
				margin-right: 1rem;
			}
			.loan-offer-box-installment {
				margin-left: 1rem;
			}
		}
	}

	.loan-offer-table {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-top: 20px;
		font-size: 1.5rem;

		.loan-offer-item {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding-bottom: 0.5rem;

			div:first-child {
				color: $primary-warmgray-11;
			}

			.loan-offer-item-information {
				padding-right: 20px;
				display: flex;
			}
			.loan-offer-item-value {
				padding-left: 20px;
				font-weight: 600;
			}
		}
		@media screen and (min-width: $screen-lg) {
			flex-direction: row;
			border: 1px solid $primary-warmgray-5;
			border-radius: $border-radius-base;
			padding: 20px;
			text-align: center !important;

			.loan-offer-item {
				flex-direction: column;
				padding-bottom: 0;

				.loan-offer-item-information {
					padding-right: 0;
				}
				.loan-offer-item-value {
					padding-left: 0;
				}
			}
		}
	}
}
