@import 'common/styling/_variables.scss';

#application-page {
	position: relative;
	min-height: 100vh;
	max-width: 1440px;
	margin: 0 auto;
	width: 100%;
	background-color: $cream-rich;
	display: flex;
	flex-direction: column;

	hr {
		width: 100%;
		border-top: 1px solid $primary-warmgray-5;
	}

	input {
		&.form-control:disabled,
		&.form-control[readonly] {
			background-color: rgba(238, 238, 238, 0.452);
			color: #979797;
		}
	}
}

#application-content-page {
	margin: 3rem 0;
}
