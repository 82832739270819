@import 'common/styling/_variables.scss';

.idletimeout-modal {
	.modal-dialog {
		top: 10%;

		&.outOfService {
			top: 0;
		}
	}

	.logo {
		width: unset;
	}
	.loading-container {
		display: flex;
		justify-content: center;
		margin-top: 1.5rem;

		.loading {
			margin-bottom: 1rem;
		}
	}
	.loading {
		color: $primary-bronze-1;
		font-size: 3px;
		position: relative;
		text-indent: -9999em;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
		border-radius: 50%;
		width: 2.5em;
		height: 2.5em;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation: load7 1.8s infinite ease-in-out;
		animation: load7 1.8s infinite ease-in-out;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			border-radius: 50%;
			width: 2.5em;
			height: 2.5em;
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
			-webkit-animation: load7 1.8s infinite ease-in-out;
			animation: load7 1.8s infinite ease-in-out;
		}

		&:before {
			left: -3.5em;
			-webkit-animation-delay: -0.32s;
			animation-delay: -0.32s;
		}

		&:after {
			left: 3.5em;
		}
	}
}

@-webkit-keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}

	40% {
		box-shadow: 0 2.5em 0 0;
	}
}

@keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}

	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
