/* Consider caution before editing this file */
@import 'common/styling/forms.scss';
@import 'common/styling/popover-tooltip';

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	src: url('./common/fonts/Raleway/Raleway-VariableFont_wght.ttf')
		format('truetype');
}

@font-face {
	font-family: 'Raleway';
	font-style: italic;
	src: url('./common/fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf')
		format('truetype');
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	height: 100%;
	font-size: 10px;

	body {
		min-height: 100vh;
		height: 100%;
		margin: 0;
		font-family: 'Raleway', Arial;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: #f6f5ee; //main bg color
		@include fontBase;
	}

	code {
		font-family: 'Raleway', Arial;
	}

	.pointer {
		cursor: pointer;
	}

	.noscroll {
		overflow: hidden;
	}
}
