@import 'common/styling/_variables.scss';

.info-box {
	background-color: $cream-dark;
	position: relative;
	min-height: 58px;
	padding: 37px 30px 37px 80px;
	margin-top: 2rem;
	width: 100%;
	border-radius: $border-radius-base;

	&.info {
		&::before {
			content: '';
			background: url('/img/information-icons/info_bronze.svg');
			display: inline-block;
			background-size: cover;
			width: 25px;
			height: 25px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	&.info-gray {
		&::before {
			content: '';
			background: url('/img/information-icons/info_gray.svg');
			display: inline-block;
			background-size: cover;
			width: 25px;
			height: 25px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	&.success {
		position: relative;
		min-height: 58px;
		margin: 1rem 0;
		min-height: 94px;

		&::before {
			content: '';
			background: url('/img/form-field-icons/tick/tick_bronze.svg');
			display: inline-block;
			background-size: cover;
			width: 31px;
			height: 21px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	&.fail {
		color: $danger;
		margin: 1rem auto 2.4rem auto;
		position: relative;
		min-height: 58px;

		&::before {
			content: '';
			background: url('/img/error-icons/info-icon-error-darkred.svg');
			display: inline-block;
			background-size: cover;
			width: 25px;
			height: 25px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	&.post {
		padding: 37px 10px 37px 90px;
		margin: 1rem 0;
		min-height: 94px;

		&::before {
			content: '';
			background: url('/img/information-icons/post-gray.svg');
			display: inline-block;
			background-size: cover;
			width: 35px;
			height: 25px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}
}
